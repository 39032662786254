import shell from 'media/Image/clients/shell.png'
import chint from 'media/Image/clients/chint.png'
import essi from 'media/Image/clients/essi.png'
import agromir from 'media/Image/clients/agromir.png'
import delkor from 'media/Image/clients/delkor.png'
import winiron from 'media/Image/clients/winiron.jpg'
import micking from 'media/Image/clients/micking.png'
import wulsage from 'media/Image/clients/wulsage.png'
import rove from 'media/Image/clients/rove.png'
import globalOil from 'media/Image/clients/globalOil.png'
import nura from 'media/Image/clients/nura.jpg'
import ready from 'media/Image/clients/ready.jpg'
import tekfor from 'media/Image/clients/tekfor.jpg'
import novatek from 'media/Image/clients/novatek.jpg'
import kbt from 'media/Image/clients/kbt.jpg'
import mutlusan from 'media/Image/clients/mutlusan.jpg'
import musa from 'media/Image/clients/musa.png'
import ferro from 'media/Image/clients/Ferro Orbita.png'
import marakanda from 'media/Image/clients/marakanda.jpg'
import soudal from 'media/Image/clients/soudal.png'
import erbos from 'media/Image/clients/er-bos.png'
import qand from 'media/Image/clients/qand.png'
import panamilk from 'media/Image/clients/panamilk.png'
import polisan from 'media/Image/clients/polisan.jpg'
import kaser from 'media/Image/clients/kaser.png'
import bekota from 'media/Image/clients/bekota.png'
import kinder from 'media/Image/clients/kinder.png'
import makiz from 'media/Image/clients/makiz.png'
import gatter from 'media/Image/clients/gatter.png'
import smile from 'media/Image/clients/smile.png'
import sleepy from 'media/Image/clients/sleepy.png'
import rowe from 'media/Image/clients/rowe.png'
import raffaello from 'media/Image/clients/raffaello.png'
import olivia from 'media/Image/clients/olivia.png'
import nutrilak from 'media/Image/clients/nutrilak.png'
import azerchay from 'media/Image/clients/azerchay.png'
import adler from 'media/Image/clients/adler.png'
import munis from 'media/Image/clients/munis.png'
import elmakon from 'media/Image/clients/elmakon.png'
import _ from 'lodash'

export const client = [
    adler,
    raffaello,
    makiz,
    kinder,
    gatter,
    munis,
    essi,
    nutrilak,
    musa,
    bekota,
    smile,
    azerchay,
    olivia,
    sleepy,
    rowe,
    soudal,
    delkor,
    chint,
    shell,
    elmakon,
    erbos,
    qand,
    panamilk,
    polisan,
    kaser,
    agromir,
    delkor,
    winiron,
    micking,
    wulsage,
    rove,
    globalOil,
    nura,
    ready,
    tekfor,
    novatek,
    kbt,
    mutlusan,
    musa,
    ferro,
    marakanda
]


const getClients = (array = client) => {
    const third = Math.floor(array.length / 3);
    return _.chunk(array, third);
}

export default getClients
