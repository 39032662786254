import React from 'react';
import styled from 'styled-components';
import MainContainer from 'components/MainContainer';
import { Col, Row } from 'react-bootstrap';
import _ from 'lodash';
import { report } from '../../../../constants/report';
import {useTranslation} from "react-i18next";
import SlideUp from "../../../SlideUp/SlideUp";

const Wrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  @media only screen and (max-width: 992px) {
		.space-between {
      justify-content: center;
			& > :last-child {
				margin-top: 50px !important;
			}
		}
    .center {
      display: flex;
      align-items: center;
      justify-content: flex-start !important;
    }
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }
`;

const Title = styled('p')`
  font-family: var(--bold);
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 150%;
  text-align: center;
  color: #18214D;
  margin-bottom: 60px !important;

  @media only screen and (max-width: 992px) {
    margin-bottom: 25px;
    font-size: 35px !important;
    text-align: left;
  }
  @media only screen and (max-width: 470px) {
    margin-bottom: 5px;
    font-size: 24px;
    text-align: left;
  }

`;

const Item = styled('div')`
  width: 95%;
  margin: auto;
`;

const About = styled('div')`
  width: 100%;
	margin-top: 24px;
`;

const ItemTitle = styled('p')`
	font-family: var(--bold);
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #18214D;

`;

const ItemText = styled('p')`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 160%;
  color: #5C6077;

`;

const Img = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    width: 100%;
    height: 100%;
  }
`;

const FlexibleReport = () => {
	const {t} = useTranslation()
  const mappedItems = _.map(report, (item, key) => {
    const img = _.get(item, ['img']);
    const title = _.get(item, ['title']);
    const text = _.get(item, ['text']);
    return (
		 <Col key={key} xs={12} lg={4} md={6} sm={6}>
		 	<SlideUp delay={Number(`.${key + (key * 3)}`)} animationType={'bottom'}>
				<Item>
					<Img>
						<img src={img} alt={'img'}/>
					</Img>
					<About>
						<ItemTitle>
							{t(title)}
						</ItemTitle>
						<ItemText>
							{t(text)}
						</ItemText>
					</About>
				</Item>
			</SlideUp>
		 </Col>
    );
  });
  return (
	 <Wrapper>
	 		<MainContainer>
				<Row>
					<Col xs={12} md={12} className={'center'}>
						<Col sm={12} md={6} lg={6}>
							<SlideUp animationType={'bottom'}>
								<Title>
									{t('solutionMainTitle')}
								</Title>
							</SlideUp>
						</Col>
					</Col>
					<Col xs={12}>
						<Row className={'space-between'}>
							{mappedItems}
						</Row>
					</Col>
				</Row>
			</MainContainer>
	 </Wrapper>
  );
};

export default FlexibleReport;
