import React from 'react';

export default function Help (props) {
  return (
	 <svg {...props} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
		 <path d="M30 20.3027C30 15.9299 27.0536 12.1095 22.8987 10.9582C22.6376 4.87152 17.6049 0 11.4551 0C5.13863 0 0 5.13863 0 11.4551C0 13.5136 0.547943 15.518 1.58844 17.2776L0.0421143 22.8678L5.63255 21.3217C7.25006 22.2782 9.07471 22.817 10.9579 22.8983C12.109 27.0534 15.9297 30 20.3027 30C22.0482 30 23.7456 29.5351 25.237 28.6519L29.9577 29.9577L28.6519 25.237C29.5351 23.7456 30 22.0482 30 20.3027ZM5.90996 19.4211L2.56325 20.3469L3.48907 17.0002L3.27805 16.6702C2.28355 15.1142 1.75781 13.3109 1.75781 11.4551C1.75781 6.10794 6.10794 1.75781 11.4551 1.75781C16.8022 1.75781 21.1523 6.10794 21.1523 11.4551C21.1523 16.8022 16.8022 21.1523 11.4551 21.1523C9.5993 21.1523 7.79617 20.6266 6.24001 19.6321L5.90996 19.4211ZM27.4368 27.4368L24.9534 26.7496L24.6217 26.9655C23.3361 27.8007 21.8424 28.2422 20.3027 28.2422C16.8658 28.2422 13.8457 26.02 12.7757 22.8335C18.0386 22.2267 22.2267 18.0386 22.8337 12.7755C26.02 13.8457 28.2422 16.8658 28.2422 20.3027C28.2422 21.8424 27.8007 23.3361 26.9655 24.6217L26.7496 24.9534L27.4368 27.4368Z" fill="#2684FF"/>
		 <path d="M10.5762 15.8789H12.334V17.6367H10.5762V15.8789Z" fill="#2684FF"/>
		 <path d="M13.2129 8.78906C13.2129 9.28825 13.0096 9.74899 12.6407 10.0866L10.5762 11.9762V14.1211H12.334V12.7503L13.8274 11.3834C14.5539 10.7185 14.9707 9.77303 14.9707 8.78906C14.9707 6.85043 13.3937 5.27344 11.4551 5.27344C9.51645 5.27344 7.93945 6.85043 7.93945 8.78906H9.69727C9.69727 7.81975 10.4858 7.03125 11.4551 7.03125C12.4244 7.03125 13.2129 7.81975 13.2129 8.78906Z" fill="#2684FF"/>
	 </svg>
  );
}
