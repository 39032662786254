import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

function Logo (props) {
  const { isFooter } = props;
  if (isFooter) {
    return (
            <SvgIcon className={'logo'} xmlns="http://www.w3.org/2000/svg" width="53" height="86" viewBox="0 0 53 86" fill="none"
                     {...props}>
                <circle cx="43" cy="9.70967" r="9.70967" transform="rotate(90 43 9.70967)" fill="#FEFEFE"/>
                <circle cx="43" cy="43" r="9.70967" transform="rotate(90 43 43)" fill="#FEFEFE"/>
                <circle cx="9.7097" cy="9.70967" r="9.70967" transform="rotate(90 9.7097 9.70967)" fill="#FEFEFE"/>
                <circle cx="9.7097" cy="43" r="9.70967" transform="rotate(90 9.7097 43)" fill="#00B2FE"/>
                <path d="M9.70968 86C4.34718 86 1.65073e-05 81.6528 1.67417e-05 76.2903C1.69761e-05 70.9278 4.34718 66.5807 9.70968 66.5807C15.0722 66.5807 19.4194 70.9278 19.4194 76.2903C19.4194 81.6528 15.0722 86 9.70968 86Z" fill="#00B2FE" style={{ 'fill': '#00B2FE' }}/>
            </SvgIcon>
    );
  }
  return (
        <SvgIcon className={'logo'} viewBox="0 0 103 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="12.3058" cy="10.7859" r="2.78589" transform="rotate(90 12.3058 10.7859)" fill="#2684FF"/>
            <circle cx="12.3058" cy="20.3375" r="2.78589" transform="rotate(90 12.3058 20.3375)" fill="#2684FF"/>
            <circle cx="2.78589" cy="10.7859" r="2.78589" transform="rotate(90 2.78589 10.7859)" fill="#2684FF"/>
            <circle cx="2.78589" cy="20.3375" r="2.78589" transform="rotate(90 2.78589 20.3375)" fill="#18214D"/>
            <circle cx="2.78589" cy="29.8891" r="2.78589" transform="rotate(90 2.78589 29.8891)" fill="#18214D"/>
            <path
                d="M36.197 8.95734C38.5097 8.95734 40.456 9.73587 42.0359 11.2929C43.6159 12.85 44.4059 14.7619 44.4059 17.0288C44.4059 19.2957 43.6159 21.2076 42.0359 22.7647C40.456 24.3217 38.5097 25.1003 36.197 25.1003H31.9724V33H27.2325V8.95734H36.197ZM36.197 20.6696C37.2045 20.6696 38.0403 20.3261 38.7043 19.6392C39.3684 18.9293 39.7004 18.0592 39.7004 17.0288C39.7004 15.9755 39.3684 15.1054 38.7043 14.4185C38.0403 13.7315 37.2045 13.3881 36.197 13.3881H31.9724V20.6696H36.197ZM59.3158 15.8267H62.6131V33H58.2854V23.2456L50.2139 33H46.9167V15.8267H51.2443V25.5811L59.3158 15.8267ZM80.2053 15.8267V20.0857H74.8472V33H70.4165V20.0857H64.9897V15.8267H80.2053ZM100.432 15.8267V33H96.1384V22.9708L91.7763 29.8401H91.2268L86.8304 22.9021V33H82.5714V15.8267H86.9335L91.5016 23.0395L96.0697 15.8267H100.432Z"
                fill="#293856"/>
        </SvgIcon>
  );
}

Logo.propTypes = {
  'isFooter': PropTypes.bool
};

export default Logo;
