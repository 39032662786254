import React from 'react';

export default function Shape (props) {
  return (
	 <svg {...props} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
		 <path d="M12.0913 16.8929H3.01586C2.45494 16.8929 2.00024 17.3476 2.00024 17.9086V26.9841C2.00024 27.545 2.45494 27.9997 3.01586 27.9997H12.0913C12.6522 27.9997 13.1069 27.545 13.1069 26.9841V17.9086C13.1069 17.3476 12.6522 16.8929 12.0913 16.8929ZM11.0757 25.9684H4.03148V18.9242H11.0757V25.9684Z" fill="#2684FF"/>
		 <path d="M17.4635 17.1908C17.0669 16.7942 16.4239 16.7942 16.0272 17.1908C15.6306 17.5874 15.6306 18.2305 16.0272 18.6271L24.7536 27.3536C25.1502 27.7501 25.7932 27.7502 26.1899 27.3536C26.5865 26.957 26.5865 26.3139 26.1899 25.9172L17.4635 17.1908Z" fill="#2684FF"/>
		 <path d="M27.8928 12.8006L22.7733 2.56162C22.6012 2.21752 22.2496 2.00018 21.8649 2.00018C21.4802 2.00018 21.1286 2.21752 20.9565 2.56162L15.837 12.8006C15.6796 13.1154 15.6964 13.4893 15.8814 13.7887C16.0665 14.0881 16.3934 14.2704 16.7453 14.2704H26.9844C27.3364 14.2704 27.6632 14.0881 27.8483 13.7887C28.0333 13.4893 28.0501 13.1154 27.8928 12.8006ZM18.3887 12.2392L21.8649 5.28682L25.3411 12.2392H18.3887Z" fill="#2684FF"/>
		 <path d="M8.13545 2.00031C4.75258 2.00031 2.00037 4.75247 2.00037 8.13539C2.00037 11.5183 4.75258 14.2705 8.13545 14.2705C11.5183 14.2705 14.2705 11.5183 14.2705 8.13539C14.2705 4.75247 11.5184 2.00031 8.13545 2.00031ZM8.13545 12.2393C5.87256 12.2393 4.0316 10.3983 4.0316 8.13539C4.0316 5.87249 5.87256 4.03154 8.13545 4.03154C10.3983 4.03154 12.2393 5.87255 12.2393 8.13539C12.2393 10.3982 10.3983 12.2393 8.13545 12.2393Z" fill="#2684FF"/>
	 </svg>
  );
}
