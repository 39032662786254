import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const CustomIcon = styled(Box)`
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  background: #FFFFFF;
  border: 1px solid #5C60772D;
  transition: all ease 0.2s;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
`;

const Icon = (props) => {
  const { children } = props;
  return (
	 <CustomIcon>
		 {children}
	 </CustomIcon>
  );
};

Icon.propTypes = {
  'children': PropTypes.any
};

export default Icon;
