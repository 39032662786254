export default {
  ru: {
    column1Title1: 'Дистрибьюторам',
    column1Title2: 'Магазинам',
    column1Title3: 'Телеграмм бот',
    column1Title4: 'Автомобильные весы',
    column1Title5: 'Интернет магазин',

    column2Title1: 'Компания',
    column2Title2: 'Политика конфиденциальности',
    column2Title3: 'Правила использования',
    column2Title4: 'Партнеры',

    column3Title1: 'Ресурсы',
    column3Title2: 'Служба поддержки',
    column3Title3: 'Отзывы',

    copyRightTitle: 'OOO «Wiener Deming». Все права защищены.',

    "address": "Узбекистан, Ташкент, Мизро-Улугбекский район, улица Чуст, дом 15.",
    "reference_point": "Ориентир: станция метро Буюк Ипак Йули; (бывш. Максим Горький)",
    "copyright_text": "Ритм. Все права защищены.",
  },

  uz: {
    column1Title1: 'Distribyutorlar',
    column1Title2: 'Do\'konlar',
    column1Title3: 'Telegram bot',
    column1Title4: 'Avtomobil og\'irliklari',
    column1Title5: 'Internet-do\'kon',

    column2Title1: 'Kompaniya',
    column2Title2: 'Maxfiylik siyosati',
    column2Title3: 'Foydalanish shartlari',
    column2Title4: 'Hamkorlar',

    column3Title1: 'Resurslar',
    column3Title2: 'Qo\'llab-quvvatlash xizmati',
    column3Title3: 'Sharhlar',

    copyRightTitle: 'OOO «Wiener Deming». Barcha huquqlar himoyalangan.',

    "address": "O'zbekiston, Toshkent, Mirzo Ulug'bek tumani, Chust ko'chasi, 15-uy.",
    "reference_point": "Mo'ljal: Buyuk Ipak Yo'li metro bekati; (metro Maksim Gorkiy)",
    "copyright_text": "Ritm. Barcha huquqlar himoyalangan."
  },

  en: {
    column1Title1: 'Distributors',
    column1Title2: 'Markets',
    column1Title3: 'Telegram bot',
    column1Title4: 'Car weights',
    column1Title5: 'Internet shop',

    column2Title1: 'Company',
    column2Title2: 'Privacy Policy',
    column2Title3: 'Terms of Use',
    column2Title4: 'Partners',

    column3Title1: 'Resources',
    column3Title2: 'Support',
    column3Title3: 'Reviews',

    copyRightTitle: 'OOO «Wiener Deming». All rights reserved.',

    "address": "Uzbekistan, Tashkent, Mizro Ulugbek district, Chust street, house 15.",
    "reference_point": "Landmark: Buyuk Ipak Yuli metro station; (formerly Maxim Gorky)",
    "copyright_text": "Ритм. All rights reserved."
  },
}
