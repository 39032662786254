import React from 'react';
import _ from 'lodash';
import Comment from 'components/Comment';
import styled from 'styled-components';
import {Col, Row} from 'react-bootstrap';
import MainContainer from '../MainContainer';
import {comments} from 'constants/comments';
import Marquee from "react-fast-marquee";
import SlideUp from "../SlideUp/SlideUp";
import Slider from 'react-slick';

const Wrapper = styled('div')`
  width: 100%;
  margin: auto;
  padding: 0 0 80px 0;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: 992px) {
    padding: 20px 0 50px 0;
  }

  .mobile .swiper-slide {
    min-width: 280px !important;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  @media only screen and (max-width: 705px) {
    .desktop {
      display: none;
    }

    .slick-initialized .slick-slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .mobile {
      display: block;
    }
  }
  @media only screen and (min-width: 705px) {
    .mobile {
      display: none;
    }

    .desktop {
      display: block;

      .swiper-slide {
        min-width: 374px;
      }
    }
  }

  & .swiper-wrapper > :not(:first-child) {
    margin-left: 20px;
  }

  .swiper .swiper-initialized .swiper-horizontal .swiper-pointer-events {
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: 992px) {
    &:after {
      display: none !important;
    }
  }

  &:after {
    content: '';
    z-index: 100;
    right: 0;
    display: block;
    top: 0;
    width: 150px;
    position: absolute;
    height: 670px;
    background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  }

    .slick-dots li {
        & button:before {
            width: 8px;
            content: '';
            background-color: #5C6077;
            border-radius: 50%;
            height: 8px;
        }

        &.slick-active button:before {
            background-color: #2684FF;
        }
    }

    .swiper-slide {
        margin-right: 35px !important;
    }
`;

const Title = styled('p')`
  font-family: var(--bold);
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 51px;
  text-align: center;
  color: #18214D;
  margin-bottom: 30px;
  @media only screen and (max-width: 992px) {
    font-size: 35px !important;
  }
`;

const settings2 = {
    'dots': true,
    'infinite': true,
    'speed': 500,
    'slidesToShow': 1,
    'slidesToScroll': 1,
    'autoplay': false,
    'autoplaySpeed': 2000,
    'arrows': false,
    'initialSlide': 0,
    'responsive': [
        {
            'breakpoint': 810,
            'settings': {
                'slidesToShow': 2,
                'slidesToScroll': 1
            }
        },
        {
            'breakpoint': 440,
            'settings': {
                'slidesToShow': 1,
                'slidesToScroll': 1
            }
        }
    ]
};

const Comments = ({t}) => {
    return (
        <Wrapper id={'reviews'}>
            <MainContainer style={{'margin': 'auto'}}>
                <Row>
                    <Col xs={12}>
                        <SlideUp>
                            <Title>
                                {t('clientsComments')}
                            </Title>
                        </SlideUp>
                    </Col>
                </Row>
            </MainContainer>
            <div className={'desktop'}>
                <Marquee style={{overflowX: 'clip'}} pauseOnHover behavior="scroll" direction="right" scrollamount="0">
                    {
                        _.map(comments, (item, key) => {
                            const name = _.get(item, ['name'])
                            const direction = _.get(item, ['direction'])
                            const text = _.get(item, ['text'])
                            const img = _.get(item, ['img'])
                            return (
                                <Comment
                                    styles={{marginRight: '30px'}}
                                    name={t(name)}
                                    direction={t(direction)}
                                    text={t(text)}
                                    img={img}
                                />
                            );
                        })
                    }
                </Marquee>
            </div>
            <div className={'mobile'}>
                <Slider {...settings2}>
                    {
                        _.map(comments, (item, key) => {
                            const name = _.get(item, ['name'])
                            const direction = _.get(item, ['direction'])
                            const text = _.get(item, ['text'])
                            const img = _.get(item, ['img'])
                            return (
                                <Comment
                                    styles={{margin: '30px 0'}}
                                    name={t(name)}
                                    direction={t(direction)}
                                    text={t(text)}
                                    img={img}
                                />
                            );
                        })
                    }
                </Slider>
            </div>
        </Wrapper>
    );
};

export default Comments;
