import React from 'react';
import {Col, Row} from 'react-bootstrap';
import _ from 'lodash';
import phone from 'media/Image/new/kpi.png';
import styled from 'styled-components';
import Icon from 'components/Icon';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

const Wrapper = styled(Row)`
  padding-top: 12px;
  padding-bottom: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;

  .slick-dots li {
    & button:before {
      width: 8px;
      content: '';
      background-color: #5C6077;
      border-radius: 50%;
      height: 8px;
    }

    &.slick-active button:before {
      background-color: #2684FF;
    }
  }

  @media only screen and (max-width: 620px) {
    .slick-list {
      width: 150% !important;
    }
  }

  .swiper-slide {
    margin-right: 35px !important;
  }
`;

const Item = styled(Col)`
  margin-top: 30px !important;
  display: flex;
  align-items: center;
  gap: 20px;
  
  :first-child {
    margin-top: 45px !important;
  }
`

const ItemTitle = styled('p')`
  font-style: normal;
  font-family: var(--bold);
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #18214D;
  margin-top: 24px;
`;

const ItemText = styled('p')`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 180%;
  color: #5C6077;
  max-width: 374px;
  margin-top: -2px;
	& > span {
		font-weight: 600;
	}
`;

const Img = styled('div')`
  display: flex;
  justify-content: center;
	margin-top: 1
  0px;
	width: 100%;
	height: 450px;
  @media only screen and (max-width: 992px) {
    margin-left: 0;
    & img {
      margin-left: 20px;
    }
    & img {
      margin-left: 0 !important;
    }
  }
`;

const MobileItem = styled('div')`
  margin-top: 48px !important;
  padding-left: 24px !important;
  max-width: 260px;
  min-height: 177px;
  @media only screen and (max-width: 380px) {
    padding: 0 !important;
    width: 300px !important;
  }
`;

const settings2 = {
  'dots': true,
  'infinite': true,
  'speed': 500,
  'slidesToShow': 3,
  'slidesToScroll': 1,
  'autoplay': false,
  'autoplaySpeed': 2000,
  'arrows': false,
  'initialSlide': 0,
  'responsive': [
    {
      'breakpoint': 810,
      'settings': {
        'slidesToShow': 2,
        'slidesToScroll': 1
      }
    },
    {
      'breakpoint': 440,
      'settings': {
        'slidesToShow': 1,
        'slidesToScroll': 1
      }
    }
  ]
};


const Services = ({ object }) => {
  const {t} = useTranslation()
  return (
	 <Wrapper>
		 <Col xs={12}>
			 <Img>
				 <img src={phone} alt=""/>
			 </Img>
			 {/*<Slider {...settings2}>*/}
				{/* {*/}
				{/*	 _.map(object, (item, key) => {*/}
             {/*            const text = _.split(t(_.get(item, ['text'])), '||');*/}
				{/*		 const icon = _.get(item, ['icon']);*/}
				{/*		 return (*/}
				{/*			<MobileItem key={key}>*/}
             {/*                   <Icon>*/}
             {/*                       {icon}*/}
             {/*                   </Icon>*/}
             {/*                   <ItemTitle>*/}
             {/*                       {text[0]}*/}
             {/*                   </ItemTitle>*/}
				{/*				<ItemText>*/}
             {/*                       {text[1]}*/}
				{/*				</ItemText>*/}
				{/*			</MobileItem>*/}
				{/*		 );*/}
				{/*	 })*/}
				{/* }*/}
			 {/*</Slider>*/}
             <Row style={{display: 'flex', flexDirection: 'column'}}>
                 {
                     _.map(object, (item, key) => {
                         const text = _.split(t(_.get(item, ['text'])), '||');
                         const icon = _.get(item, ['icon']);
                         return (
                             <Item key={key} lg={3} xs={12} md={6} sm={12}>
                                 <Icon>
                                     {icon}
                                 </Icon>
                                 <ItemTitle>
                                     {text[0]}
                                 </ItemTitle>
                             </Item>
                         )
                     })
                 }
             </Row>
		 </Col>
	 </Wrapper>
  );
};

Services.propTpyes = {
  'object': PropTypes.object
};

Services.propTypes = {
  'object': PropTypes.object
};

export default Services;
