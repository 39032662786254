import React from 'react';
import styled from 'styled-components';
import {Col, Row} from 'react-bootstrap';
import Icon from 'components/Icon';
import _ from 'lodash';
import phone from 'media/Image/new/kpi.png';
import Services from './Services';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import MainContainer from "../../../MainContainer";
import SlideUp from "../../../SlideUp/SlideUp";

const Wrapper = styled(Row)`
  margin-right: 0;
  margin-left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({background}) => background || 'inherit'};
  flex-direction: column;
  @media only screen and (max-width: 992px) {
    padding: 50px 0;
    .center {
      display: flex;
      align-items: center;
      justify-content: flex-start !important;
    }
  }

  padding: 80px 0;
  @media only screen and (max-width: 995px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }
  }
  @media only screen and (min-width: 995px) {
    .mobile {
      display: none;
    }

    .desktop {
      display: block;
    }
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
  }
`;

const Title = styled('p')`
  font-family: var(--bold);
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 150%;
  text-align: center;
  color: #18214D;
  margin-bottom: 60px;

  @media only screen and (max-width: 992px) {
    margin-bottom: 25px;
    font-size: 25px !important;
    text-align: left;
  }
  @media only screen and (max-width: 470px) {
    margin-bottom: 5px;
    font-size: 24px;
    text-align: left;
  }

`;

const Steps = styled('div')`
  width: 100%;

  & > :not(:last-child) {
    margin-bottom: 48px;
  }
`;

const Img = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 992px) {
    & img {
      height: 460px !important;
    }
  }

  img {
    width: 325px;
  }
`;

const Step = styled('div')`
  width: 100%;
`;

const StepText = styled('p')`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 180%;
  color: #18214D;
  margin-top: 16px;
  & > span {
    font-weight: 600;
  }
`;
const Flex = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;


  & > p {
    font-style: normal;
    font-family: var(--bold);
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #18214D;
    margin: 0;
  }

`

const step = (object, t) => {
  return (
        <Steps>
            {
                _.map(object, (item, key) => {
                  const icon = _.get(item, ['icon']);
                    const text = _.split(t(_.get(item, ['text'])), '||');
                  return (
                        <Step key={key}>
                            <Flex>

                                <Icon>
                                    {icon}
                                </Icon>
                                <p>{text[0]}</p>
                            </Flex>
                            <StepText>
                                {text[1]}
                            </StepText>
                        </Step>
                  );
                })
            }
        </Steps>
  );
};

const AgentSection = ({object}) => {
  const merge = [..._.get(object, ['first']), ..._.get(object, ['second'])];

    const {background} = object

    const {t} = useTranslation()
  return (
      <div style={{width: '100%', background, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <MainContainer>
              <Wrapper>
                  <Col xs={12} md={12} className={'center'}>
                      <Col sm={12} md={8} lg={8}>
                          <SlideUp>
                              <Title>
                                  {t('section1Title')}
                              </Title>
                          </SlideUp>
                      </Col>
                  </Col>
                  <Col className={'desktop'} xs={12}>
                      <Row>
                          <Col lg={4}>
                              <SlideUp animationType={'left'} duration={.3}>
                                  {step(_.get(object, ['first']), t)}
                              </SlideUp>
                          </Col>
                          <Col lg={4}>
                              <SlideUp animationType={'bottom'} duration={.3}>
                              <Img>
                                  <img src={phone} alt=""/>
                              </Img>
                              </SlideUp>
                          </Col>
                          <Col lg={4}>
                              <SlideUp animationType={'right'} duration={.3}>

                              {step(_.get(object, ['second']), t)}
                              </SlideUp>
                          </Col>
                      </Row>
                  </Col>
                  <Col className={'mobile'} xs={12}>
                      <Services object={merge}/>
                  </Col>
              </Wrapper>
          </MainContainer>
      </div>
  );
};

AgentSection.propTypes = {
  'object': PropTypes.object
};

export default AgentSection;
