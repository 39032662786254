import React from 'react';

export default function Delivery (props) {
  return (
	 <svg {...props} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
		 <g clipPath="url(#clip0_998_7101)">
			 <path d="M22.6573 17.8361C20.5721 17.8361 18.8757 19.5324 18.8757 21.6176C18.8757 23.7028 20.5721 25.3991 22.6573 25.3991C24.7428 25.3991 26.4388 23.7028 26.4388 21.6176C26.4388 19.5324 24.7424 17.8361 22.6573 17.8361ZM22.6573 23.5083C21.6145 23.5083 20.7665 22.6603 20.7665 21.6176C20.7665 20.5748 21.6145 19.7268 22.6573 19.7268C23.7 19.7268 24.548 20.5748 24.548 21.6176C24.548 22.6604 23.7 23.5083 22.6573 23.5083Z" fill="#2684FF"/>
			 <path d="M9.73697 17.8361C7.65179 17.8361 5.95544 19.5324 5.95544 21.6176C5.95544 23.7028 7.65179 25.3991 9.73697 25.3991C11.8221 25.3991 13.5185 23.7028 13.5185 21.6176C13.5185 19.5324 11.8221 17.8361 9.73697 17.8361ZM9.73697 23.5083C8.69423 23.5083 7.84621 22.6603 7.84621 21.6176C7.84621 20.5748 8.69423 19.7268 9.73697 19.7268C10.7794 19.7268 11.6277 20.5748 11.6277 21.6176C11.6277 22.6604 10.7797 23.5083 9.73697 23.5083Z" fill="#2684FF"/>
			 <path d="M25.2042 7.01218C25.0435 6.69296 24.7167 6.49158 24.3594 6.49158H19.3804V8.38234H23.7764L26.3506 13.5025L28.0404 12.6529L25.2042 7.01218Z" fill="#2684FF"/>
			 <path d="M19.8213 20.7039H12.668V22.5946H19.8213V20.7039Z" fill="#2684FF"/>
			 <path d="M6.90133 20.7039H3.62406C3.10187 20.7039 2.67871 21.1271 2.67871 21.6492C2.67871 22.1714 3.10193 22.5946 3.62406 22.5946H6.90139C7.42357 22.5946 7.84674 22.1713 7.84674 21.6492C7.84674 21.127 7.42351 20.7039 6.90133 20.7039Z" fill="#2684FF"/>
			 <path d="M29.8016 14.9243L27.9421 12.5294C27.7634 12.2987 27.4877 12.1638 27.1955 12.1638H20.3257V5.54618C20.3257 5.02399 19.9025 4.60083 19.3804 4.60083H3.62406C3.10187 4.60083 2.67871 5.02405 2.67871 5.54618C2.67871 6.06831 3.10193 6.49153 3.62406 6.49153H18.435V13.1092C18.435 13.6314 18.8582 14.0545 19.3803 14.0545H26.7326L28.1093 15.828V20.7037H25.4938C24.9716 20.7037 24.5484 21.1269 24.5484 21.649C24.5484 22.1712 24.9716 22.5944 25.4938 22.5944H29.0547C29.5769 22.5944 30 22.1712 30.0001 21.649V15.5042C30.0001 15.2943 29.9301 15.0901 29.8016 14.9243Z" fill="#2684FF"/>
			 <path d="M6.83868 15.9139H2.48991C1.96772 15.9139 1.54456 16.3372 1.54456 16.8593C1.54456 17.3815 1.96778 17.8046 2.48991 17.8046H6.83862C7.36081 17.8046 7.78397 17.3814 7.78397 16.8593C7.78403 16.3372 7.36081 15.9139 6.83868 15.9139Z" fill="#2684FF"/>
			 <path d="M9.0126 12.1953H0.945352C0.423223 12.1953 0 12.6185 0 13.1407C0 13.6629 0.423223 14.0861 0.945352 14.0861H9.0126C9.53479 14.0861 9.95795 13.6629 9.95795 13.1407C9.95795 12.6186 9.53479 12.1953 9.0126 12.1953Z" fill="#2684FF"/>
			 <path d="M10.5572 8.47699H2.48991C1.96772 8.47699 1.54456 8.90021 1.54456 9.42234C1.54456 9.94453 1.96778 10.3677 2.48991 10.3677H10.5572C11.0793 10.3677 11.5025 9.94447 11.5025 9.42234C11.5026 8.90021 11.0793 8.47699 10.5572 8.47699Z" fill="#2684FF"/>
		 </g>
		 <defs>
			 <clipPath id="clip0_998_7101">
				 <rect width="30" height="30" fill="white"/>
			 </clipPath>
		 </defs>
	 </svg>
  );
}
