import React from 'react';
import styled from 'styled-components';
import {Col, Row} from 'react-bootstrap';
import farm from 'media/Image/farm.png';
import {ArrowRight} from 'react-feather';
import Icon from 'components/Icon';
import _ from 'lodash';
import {solutions} from 'constants/report';
import {Link} from 'react-router-dom';
import dashboard from 'media/dashboard.png';
import delivery from 'media/delivery.png';
import SlideUp from "../../../SlideUp/SlideUp";
import {useTranslation} from "react-i18next";


const Wrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .odd {
    & > :nth-child(3),
    & > :nth-child(4) {
      margin-top: 23px !important;
    }

    & > * {
      & > div {
        margin-left: 23px !important;
      }
    }
  }


	.odd {
		justify-content: space-evenly;

		& > :nth-child(3),
		& > :nth-child(4) {
			margin-top: 0 !important;
		}

		& > * {
			& > div {
				margin-left: 0 !important;
			}
		}
	}
}
`;

const ProductsRow = styled(Row)`
  width: 100%;
  margin-left: 0;
  margin-right: 0;

  .center {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
  }
`;

const Title = styled('p')`
  font-family: var(--bold);
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 150%;
  text-align: center;
  color: #18214D;
  margin-bottom: 60px;

  @media only screen and (max-width: 992px) {
    margin-bottom: 25px;
    font-size: 28px !important;
    text-align: left;
  }
  @media only screen and (max-width: 470px) {
    margin-bottom: 5px;
    font-size: 24px;
    text-align: left;
  }

`;

const ItemWrapper = styled('div')`
  padding: 10px;
  width: 100%;

  @media only screen and (max-width: 768px) {
    padding: 10px 0;
  }
`

const Item = styled('div')`
	width: 100%;
	@media only screen and (max-width: 994px) {
		margin: initial;
	}

	.MuiButton-label {
		justify-content: flex-start;
	}
min-height: 474px !important;
	transition: all ease 0.2s;
	border: 1px solid rgba(255, 0, 0, 0);
	background: #F6F7FA;
	border-radius: 16px;
	padding: ${({padding}) => padding || '36px 25px'};
	height: fit-content;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;

	&:hover {
		border: 1px solid #377DFF;
		transition: all ease 0.2s;
		background-color: white;

	}
`;

const Img = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  width: 300px;
  height: 200px;

  & img {

    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

const ItemTitle = styled('div')`
  font-style: normal;
  font-weight: bold;
  font-family: var(--bold);
  font-size: ${({ size }) => size || '24px'};
  line-height: 29px;
  color: #18214D;
`;

const ItemText = styled('p')`
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #363940;
`;

const About = styled('div')`
  width: ${({ width }) => width || '90%'};
`;

const Arrow = styled('div')`
	width: 20px !important;
  height: 20px !important;
  margin-left: 5px;
  margin-top: -5px;
  transform: rotate(-45deg);

  & img {
    margin: 0 !important;
    width: 100%;
    height: 100%;
  }
`;

const Child = styled('div')`
  background: #F6F7FA;
  border-radius: 16px;
  min-height: 256px;
  height: fit-content;
  border: 1px solid rgba(255, 0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all ease 0.2s;
  @media only screen and (max-width: 994px) {
    margin: initial;
  }

  &:hover {
    border: 1px solid #377DFF;
    transition: all ease 0.2s;

    background-color: white;

    & > :first-child > :first-child {
      background: #F6F7FA !important;
      transition: all ease 0.2s;
    }
  }

  .MuiButton-label {
    justify-content: flex-start;
  }

  padding: 24px 30px;
  margin: auto;
`;

const CustomLink = styled('a')`
	display: flex;
	align-items: center;
	text-decoration: none;
`;

const Flex = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
background: none !important;

  & > p {
    font-style: normal;
    font-family: var(--bold);
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #18214D;
    margin: 0;
  }

`

const Projects = () => {
	const {t} = useTranslation()

	const button = (
		<CustomLink href={'#contact'}>
			{t('learnMore')}
			<Arrow>
				<ArrowRight/>
			</Arrow>
		</CustomLink>
	);

  const mappedItems = _.map(solutions, (item, key) => {
    const icon = _.get(item, ['icon']);
    const text = _.get(item, ['text']);
    const title = _.get(item, ['title']);
    return (
		 <Col key={key} className={'center'} xs={12} lg={4} md={6}>
			 <SlideUp delay={Number(`.${key + (key * 3)}`)} >
				 <ItemWrapper>
					 <Child>
						 <Flex>

							 <Icon>
								 {icon}
							 </Icon>
							 <p>
								 {t(title)}
							 </p>
						 </Flex>
						 <About style={{ 'marginTop': '14px' }} width={'100%'}>
							 <ItemText>
								 {t(text)}
							 </ItemText>
							 {button}
						 </About>
					 </Child>
				 </ItemWrapper>
			 </SlideUp>
		 </Col>
    );
  });
  return (
	 <Wrapper>
			 <ProductsRow>
				 <Row className={'center'}>
					 <Col sm={12} md={12} lg={6}>
						 <SlideUp>
							 <Title>
								 {t('even_more_solutions_for_your_business')}
							 </Title>
						 </SlideUp>
					 </Col>
				 </Row>
				 <Row>
					 <Col className={'center'} xs={12} lg={4} md={6}>
						<SlideUp delay={.1}>
							<ItemWrapper>
								<Item>
									<Img>
										<img src={farm} alt={'img'}/>
									</Img>
									<About>
										<ItemTitle>
											{t('ritm_pharm')}
										</ItemTitle>
										<ItemText>
											{t('ritm_pharm_text')}
										</ItemText>
										{button}
									</About>
								</Item>
							</ItemWrapper>
						</SlideUp>
					 </Col>
					 <Col className={'center'} xs={12} lg={4} md={6}>
						<SlideUp delay={.4}>
							<ItemWrapper>
								<Item>
									<Img>
										<img src={dashboard} alt={'img'}/>
									</Img>
									<About>
										<ItemTitle>
											{t('store_automation')}
										</ItemTitle>
										<ItemText>
											{t('store_automation_text')}
										</ItemText>
										{button}
									</About>
								</Item>
							</ItemWrapper>
						</SlideUp>
					 </Col>
					 <Col className={'center'} xs={12} lg={4} md={6}>
						<SlideUp delay={.7}>
							<ItemWrapper>
								<Item>
									<Img>
										<img style={{width: '90%'}} src={delivery} alt={'img'}/>
									</Img>
									<About>
										<ItemTitle>
											{t('telegram_bot')}
										</ItemTitle>
										<ItemText>
											{t('telegram_bot_text')}
										</ItemText>
										{button}
									</About>
								</Item>
							</ItemWrapper>
						</SlideUp>
					 </Col>
					 {mappedItems}
				 </Row>
			 </ProductsRow>
	 </Wrapper>
  );
};

export default Projects;
