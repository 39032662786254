import Location from 'media/Icon/Location';
import Money from 'media/Icon/Money';
import Todo from 'media/Icon/Todo';
import React from "react";
import Trolley from 'media/Icon/Trolley';
import Delivery from 'media/Icon/Delivery';
import Process from 'media/Icon/Process';
import Sales from "components/Pages/Agent/components/Sales";
import Sell from "media/Icon/Sell";
import Shop from "media/Icon/Shop";
import Discipline from "media/Icon/Discipline";
import Statistic from "media/Icon/Statistic";

export const firstProduct = {
	'background': '#F6FAFF',
	title: 'product1Title',
	text: '',
	first: [
		{
			icon: <Statistic/>,
			text: 'product1Text1'
		},
		{
			icon: <Sell fill={'#2684FF'}/>,
			text: 'product1Text2'
		},
		{
			icon: <Shop/>,
			text: 'product1Text3'
		}
	],
	second: [
		{
			icon: <Money fill={'#2684FF'}/>,
			text: 'product1Text4'
		},
		{
			icon: <Delivery/>,
			text: 'product1Text5'
		},
		{
			icon: <Discipline style={{transform: 'scale(.7)'}} />,
			text: 'product1Text6'
		}
	]
}

export const secondProduct = {
	title: 'product2Title',
	text: 'product2MainText',
	first: [
		{
			icon: <Todo/>,
			text: 'product2Text1'
		},
		{
			icon: <Money fill={'#2684FF'}/>,
			text: 'product2Text2'
		},
		{
			icon: <Process/>,
			text: 'product2Text3'
		}
	],
	second: [
		{
			icon: <Delivery/>,
			text: 'product2Text4'
		},
		{
			icon: <Location/>,
			text: 'product2Text5'
		},
		{
			icon: <Trolley/>,
			text: 'product2Text6'
		}
	]
}

export const agentFirstProduct = {
	'title': 'Мотивируем сотрудников и поддерживаем дисциплину',
	'text': '',
	'first': [
		{
			'icon': <Sell/>,
			'title': 'KPI на продажу',
			'text': 'Dayte bonus ot fakticheskix prodaj ili ot fonda i ustanovite gradatsiyu '
		},
		{
			'icon': <Trolley />,
			'title': 'KPI на ассортимент и ММЛ',
			'text': 'Ustanovite na visokamarjinalnie produkti drugie KPI dlya uvelicheniya pribili'
		},
		{
			'icon': <Todo/>,
			'title': 'KPaaI на АКБ',
			'text': 'Uvelichte pokritiya territoriy dopolnitelno mojete ustanovit KPI na AKB na opredelleniy assortiment'

		}
	],
	'second': [
		{
			'icon': <Money fill={'#2684FF'}/>,
			'title': 'KPI за оплату',
			'text': 'fisrtProductSpan4'
		},
		{
			'icon': <Delivery/>,
			'title': 'KPI за доставку',
			'text': 'fisrtProductSpan5'
		},
		{
			'icon': <Process/>,
			'title': 'KPI на дисиплину',
			'text': 'firstProductSpan6'
		}
	]
}


export const firstSection = {
	background: 'inherit',
	reverse: true,
	title: 'phoneSectionMainTitle',
	steps: [
		{
			title: 'phoneSectionTitle1',
			text: 'phoneSectionText1'
		},
		{
			title: 'phoneSectionTitle2',
			text: 'phoneSectionText2'
		},
		{
			title: 'phoneSectionTitle3',
			text: 'phoneSectionText3'
		},
		{
			title: 'phoneSectionTitle4',
			text: 'phoneSectionText4'
		}
	]
}
export const prpductsFirstSection = {
	background: 'inherit',
	reverse: true,
	title: 'phoneSectionMainTitle',
	steps: [
		{
			title: 'phoneSectionTitle1',
			text: 'phoneSectionText1'
		},
		{
			title: 'phoneSectionTitle2',
			text: 'phoneSectionText2'
		},
		{
			title: 'phoneSectionTitle3',
			text: 'phoneSectionText3'
		},
		{
			title: 'phoneSectionTitle4',
			text: 'phoneSectionText4'
		}
	]
}

export const secondSection = {

	'background': '#F6FAFF',
	'title': 'laptopMainTitle',
	'steps': [
		{
			'text': 'laptopText1'
		},
		{
			'text': 'laptopText2'
		},
		{
			'text': 'laptopText3'
		},
		{
			'text': 'laptopText4'
		},
		{
			'text': 'laptopText5'
		},
		{
			'text': 'laptopText6'
		}
	]

}

export const thirdSections = {
	background: 'inherit',
	title: 'Не теряйте время и деньги',
	steps: [
		{
			text: 'Система сама покажет расположение торговых точек'
		},
		{
			text: 'Теперь одну точку не нужно создавать два раза'
		},
		{
			text: 'Маршрут сразу отображается на карте'
		},
		{
			text: 'Возможность увидеть расположение агента и их статус'
		},
		{
			text: 'Работает без интернета'
		},
		{
			text: 'Возможность ставить ограничения клиентам, если есть задолжность'
		}
	]
}

export const header = {
	background: 'inherit',
	title: 'homeTitle',
	steps: [
		'Моментально уведомляем о проблемах',
		'Повышаем эффективность каждого сотрудника',
		'Маркетенговые инструменты для увеличения продаж',
		'Ускорим оборот денег',
		'Интеграция с оборудованием'
	]
}
