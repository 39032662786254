import React from 'react';
import {Typography} from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CustomText = styled(Typography)`
  font-style: normal;
  font-weight: ${({fontWeight}) => fontWeight};
  width: ${({width}) => width} !important;
  font-size: ${({size}) => size || '18px'};
  margin: auto;
  line-height: ${({lineHeight}) => lineHeight || '180%'};
  text-align: ${({align}) => align || 'inherit'};
  font-family: ${({fontFamily}) => fontFamily || 'inherit'} !important;
  color: ${({customColor}) => customColor || '#18214D'};
  letter-spacing: ${({spacing}) => spacing || '0'};
`;

const Text = (props) => {
    const {children} = props;
    return (
        <CustomText {...props}>
            {children}
        </CustomText>
    );
};

Text.propTypes = {
    'children': PropTypes.any
};

export default Text;
