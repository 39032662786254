import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Logo from 'media/Icon/Logo';
import _ from 'lodash';
import {menuItems} from 'constants/menu';
import {Col, Row} from 'react-bootstrap';
import menubar from 'media/Image/menu_bar.svg';
import bga from 'media/Image/bg.svg';
import {ArrowRight} from 'react-feather';
import $ from 'jquery';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import Rus from 'media/Icon/Flags/russia.svg'
import Uzb from 'media/Icon/Flags/uzbekistan.svg'
import Eng from 'media/Icon/Flags/united.svg'
import Button from "../Button";
import '../../styles/normalize.css'


const Wrapper = styled('div')`
  width: 100%;
  z-index: 4999;
  padding: 28px 20px 0 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

  & .row {
    margin-left: 0;
    margin-right: 0;
  }
`;

const Container = styled('div')`
  max-width: 1170px;
  width: 1170px;
 
  margin: auto;
  @media only screen and (max-width: 1200px) {
    & {
      width: 100%;
    }
  }
`;

const LogoItem = styled('a')`
  width: auto;
  display: flex;

  & svg {
    width: 121px;
    height: 41px;
  }
`;

const Menu = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MenuItem = styled('a')`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-decoration: none;
  font-family: var(--bold);
  color: #18214D;
  border-radius: 16px;
  padding: 16px;
  font-size: 18px !important;
  border-left: 3px solid inherit;

  &:hover {
    background: white;
    cursor: pointer;
    transition: all 0.5s ease 0s;
    color: rgb(0, 101, 255);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 32px;
  }
`;

const PhoneItem = styled('a')`
  font-style: normal;

  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-decoration: none;
  color: #18214D;
  border-left: 3px solid inherit;

  & > p {
    line-height: 24px;
    font-size: 20px;
    font-weight: 600;
    font-family: var(--bold);
    margin-bottom: 0;
  }
`;

const MenuBar = styled('div')`
  width: 32px;
  height: 22px;
  cursor: pointer;
  display: none;
  @media only screen and (max-width: 992px) {
    & {
      display: block;
    }
  }

  & img {
    width: 100%;
    height: 100%;
  }
`;

const MobileTopRight =  styled('div')`
    width: max-content;
    display: none;
    
    @media only screen and (max-width: 992px) {
        & {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }

    @media only screen and (max-width: 380px) {
        & {
            display: flex;
            align-items: center;
            gap: 4px;
        }
    }
`

const Close = styled('div')`
  position: absolute;
  top: 30px;
  cursor: pointer;
  right: 31px;
  display: none;
  @media only screen and (max-width: 992px) {
    & {
      display: block;
    }
  }
`;

const Nav = styled(Row)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 410px) {
    & .col-12 {
      width: 100%;
    }
  }
  @media only screen and (max-width: 992px) {

    & {
      flex-direction: column;
      width: 100%;
      margin: auto;

      & .col-2 {
        width: 100%;
        z-index: 10;

        & .row {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      & .col-9 {
        position: fixed;
        width: 0;
        overflow: hidden;
        z-index: 100000;
        height: 100%;
        background: rgba(83, 95, 120, 0.5);
        top: 0;
        overflow: hidden;
        right: -100%;
        opacity: 0;
        z-index: 0;
        transition: 0s;
        transition-delay: 0.4s;
        

        &.active {
          opacity: 1;
          right: 0;
          width: 100%;
          transition: 0s;
          background: white !important;
          z-index: 100000;
        }
      }

      & .col-12 {
        position: absolute;
        overflow: hidden;
        width: 0;
        height: 100vh;
        right: -100%;
        top: 0;
        background: url(${({bg}) => bg || bga}) center center / cover;
        transition: 0.4s;
        opacity: 0;

        &.active {
          width: 100%;
          z-index: 1000000;
          right: 0;

          body {
            overflow: hidden;
          }

          opacity: 1;
          transition: 0.4s;
        }

        & > div {
          padding-top: 145px !important;
          position: relative;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          padding-left: 24px;


          & a {
            height: 47px;
            display: flex;
            align-items: center;
            font-size: 18px;

            &.active {
              border-left: 2px solid #2684FF;
            }
          }
        }
      }
    }
  }
`;

const SelectionBox = styled('div')`
  width: 70px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 11px;
  background: inherit;
  position: relative;
  justify-content: center;
`

const Select = styled('div')`
  height: 45px;
  width: 100%;
  display: flex;
  align-items: center;

  & p {

    font-weight: 600 !important;
    font-family: var(--bold);
    margin-bottom: 0;
  }

  justify-content: center;
  border-radius: 10px;
  gap: 10px;
  background: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  color: #18214D;
  user-select: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 5px;

  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
`

const OptionBox = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  font-weight: 600;
  overflow: hidden;
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 100000;
  top: 48px;
  position: absolute;
`

const Option = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  padding: 5px 10px;
  color: #18214D;
  cursor: pointer;

  :hover {
    background: #0d6efd;
    color: #ffff;
  }

  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media only screen and (max-width: 992px) {
    flex-direction: column;
    align-items: start;
  }
`

const menu = (items, t) => {
    return (<>
            {_.map(items, (item, key) => {
                const label = _.get(item, 'label');
                const href = _.get(item, 'href');
                return (<MenuItem key={key} href={href}>
                        {t(label)}
                    </MenuItem>);
            })}
        </>);
};

const Navbar = ({bg}) => {
    const options = [
        {value: 'ru', text: 'RU', img: Rus},
        // {value: 'en', text: 'EN', img: Eng},
        {value: 'uz', text: 'UZ', img: Uzb},
    ];

    const [open, setOpen] = useState(false);

    const [openSelect, setOpenSelect] = useState(false)
    const [language, setLanguage] = useState(localStorage.getItem('language'))
    const langObj = language ? JSON.parse(language) : options[0]

    const langText = _.get(langObj, ['text'])
    const langImg = _.get(langObj, ['img'])
    const langValue = _.get(langObj, ['value'])

    const handleOpen = () => {
        setOpen(true);
        $('body').css('overflow', 'hidden');
        $('body').css('height', '100vh');
    };

    const handleClose = () => {
        setOpen(false);
        $('body').css('overflow', 'hidden scroll');
    };

    const {t, i18n} = useTranslation()
    const history = useHistory()

    const handleChange = (item) => {
        setLanguage(JSON.stringify(item))
        localStorage.setItem('language', JSON.stringify(item))
        window.location.reload()
    };

    useEffect(() => {
        i18n.changeLanguage(langValue)
        history.push('#navbar')
    }, [langValue])

    const [scrolled, setScrolled] = useState(false);

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 0) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    let navbarClasses = ['navbar'];
    if (scrolled) {
        navbarClasses.push('scrolled');
    }

    return (
        <Wrapper id={'navbar'} className={navbarClasses.join(' ')}>
            <Container>
                <Nav bg={bg}>
                    <Col xs={2}>
                        <Row>
                            <LogoItem href={'/'}>
                                <Logo/>
                            </LogoItem>

                            {/*<MenuBar onClick={() => {*/}
                            {/*    setOpen(true);*/}
                            {/*}}>*/}
                            {/*    <img src={menubar} alt={''} onClick={handleOpen}/>*/}
                            {/*</MenuBar>*/}

                            <MobileTopRight>
                                <a href="#contact">
                                    <Button
                                        background={'#2684FF'}
                                        width={'140px'}
                                        size={'14px'}
                                        height={'45px'}>
                                        {t('requestDemo')}
                                    </Button>
                                </a>
                                <SelectionBox>
                                    <Select onClick={() => setOpenSelect(!openSelect)}>
                                        <p>{langText}</p>
                                        <img src={langImg} alt=""/>
                                    </Select>
                                    <OptionBox>
                                        {openSelect
                                            ? _.map(options, (item) => {
                                                return (<Option onClick={() => {
                                                    handleChange(item)
                                                    setOpenSelect(!openSelect)
                                                }}>
                                                    {item.text}
                                                    <img alt={''} src={item.img}/>
                                                </Option>)
                                            })
                                            : null}
                                    </OptionBox>
                                </SelectionBox>
                            </MobileTopRight>
                        </Row>
                    </Col>
                    <div
                        className={open ? 'col-lg-10 col-md-10 col-10 modal-active active' : 'col-lg-10 col-md-10 col-10'}>
                        <Col style={{position: open && 'fixed'}} className={open ? 'modal-active active' : ''}
                             xs={12}>
                            <Menu>
                                {menu(menuItems, t)}
                                <Flex>
                                    {scrolled && (
                                        <a href="#contact">
                                            <Button
                                                background={'#2684FF'}
                                                width={'238px'}
                                                height={'53px'}>
                                                {t('requestDemo')}
                                            </Button>
                                        </a>
                                    )}
                                    {!scrolled && (
                                        <PhoneItem>
                                            <p> +998 78 113 88 58</p>
                                        </PhoneItem>
                                    )}
                                    <SelectionBox>
                                        <Select onClick={() => setOpenSelect(!openSelect)}>
                                            <p>{langText}</p>
                                            <img src={langImg} alt=""/>
                                        </Select>
                                        <OptionBox>
                                            {openSelect
                                                ? _.map(options, (item) => {
                                                    return (<Option onClick={() => {
                                                        handleChange(item)
                                                        setOpenSelect(!openSelect)
                                                    }}>
                                                        {item.text}
                                                        <img alt={''} src={item.img}/>
                                                    </Option>)
                                                })
                                                : null}
                                        </OptionBox>
                                    </SelectionBox>
                                </Flex>
                                <Close onClick={handleClose}><ArrowRight width={'40px'} height={'40px'}/></Close>
                            </Menu>
                        </Col>
                    </div>
                </Nav>
            </Container>
        </Wrapper>
    );
};

Navbar.propTypes = {
    'bg': PropTypes.string
};

export default Navbar;
