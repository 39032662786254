import Active from 'media/Icon/Active';
import Money from 'media/Icon/Money';
import Analytics from 'media/Icon/Analytics';
import Database from 'media/Icon/Database';
import Stars from 'media/Icon/Stars';
import Cloud from 'media/Icon/Cloud';
import Statistic from 'media/Icon/Statistic/Statistic';
import Secure from 'media/Icon/Secure';
import Code from 'media/Icon/Code';
import Experience from 'media/Icon/Expirience';
import Help from 'media/Icon/Help';
import Trolley from 'media/Icon/Trolley';
import Sell from 'media/Icon/Sell';
import React from 'react';
import Control from "media/Icon/Control";
import Users from "media/Icon/Users";
import Production from "../../media/Icon/Production";

export const services = [
    {
        'text': 'servicesTitle1',
        'icon': <Active/>
    },
    {
        'text': 'servicesTitle2',
        'icon': <Money/>
    },
    {
        'text': 'servicesTitle3',
        'icon': <Analytics/>
    },
    {
        'text': 'servicesTitle4',
        'icon': <Database/>
    },
    {
        'text': 'servicesTitle5',
        'icon': <Stars/>
    }
];

export const servicesSection = [
    {
        'icon': <Cloud/>,
        'title': 'servicesSectionTitle1',
        'text': 'servicesSectionText1'
    },
    {
        'icon': <Statistic/>,
        'title': 'servicesSectionTitle2',
        'text': 'servicesSectionText2'
    },
    {
        'icon': <Secure/>,
        'title': 'servicesSectionTitle3',
        'text': 'servicesSectionText3'
    },
    {
        'icon': <Code/>,
        'title': 'servicesSectionTitle4',
        'text': 'servicesSectionText4'
    },
    {
        'icon': <Experience/>,
        'title': 'servicesSectionTitle5',
        'text': 'servicesSectionText5'
    },
    {
        'icon': <Help/>,
        'title': 'servicesSectionTitle6',
        'text': 'servicesSectionText6'
    }
];

export const productsSection = [
    {
        'icon': <Statistic/>,
        'delay': '.3',
        'title': 'sales',
        'text': 'salesText'
    },
    {
        'icon': <Money fill={'#2684FF'}/>,
        'delay': '.4',
        'title': 'finance',
        'text': 'financeText'
    },
    {
        'icon': <Trolley/>,
        'delay': '.5',
        'title': 'stock',
        'text': 'stockText'
    },
    {
        'icon': <Sell/>,
        'delay': '.6',
        'title': 'marketing',
        'text': 'marketingText'
    },
    {
        'icon': <Users/>,
        'delay': '.7',
        'title': 'humanResourcesManagement',
        'text': 'humanResourcesManagementText'
    },
    {
        'delay': '.8',
        'icon': <Production/>,
        'title': 'production',
        'text': 'productionText'
    }
    ,
    {
        'delay': '.9',
        'icon': <Analytics style={{transform: 'scale(.9)'}} color={'#2684FF'}/>,
        'title': 'analytics',
        'text': 'analyticsText'
    },
    {
        'delay': '1',
        'icon': <Control/>,
        'title': 'audit',
        'text': 'auditText'

    }
];
