import React from 'react';
import PropTypes from 'prop-types';

function Money (props) {
  return (
	 <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		 <g clipPath="url(#clip0_866_1166)">
			 <path d="M19.1296 20.1843C24.6945 20.1843 29.2218 15.657 29.2218 10.0922C29.2218 4.52735 24.6945 0 19.1296 0C13.5647 0 9.03746 4.52735 9.03746 10.0922C9.03746 15.657 13.5648 20.1843 19.1296 20.1843ZM19.1296 2.10938C23.5314 2.10938 27.1125 5.69046 27.1125 10.0922C27.1125 14.4939 23.5314 18.075 19.1297 18.075C14.728 18.075 11.1469 14.4939 11.1469 10.0922C11.1469 5.69046 14.7279 2.10938 19.1296 2.10938Z" fill={props.fill || '#5C6077'}/>
			 <path d="M18.5648 11.1467H19.6945C20.0474 11.1467 20.3344 11.4337 20.3344 11.7866C20.3344 12.1394 20.0474 12.4265 19.6945 12.4265H18C17.4175 12.4265 16.9453 12.8987 16.9453 13.4811C16.9453 14.0636 17.4175 14.5358 18 14.5358H18.0749V14.6108C18.0749 15.1933 18.5472 15.6655 19.1296 15.6655C19.7121 15.6655 20.1843 15.1933 20.1843 14.6108V14.4913C21.4672 14.2594 22.4438 13.1354 22.4438 11.7866C22.4438 10.2706 21.2105 9.03733 19.6945 9.03733H18.5648C18.212 9.03733 17.925 8.75031 17.925 8.39748C17.925 8.04459 18.212 7.75757 18.5648 7.75757H20.2594C20.8419 7.75757 21.3141 7.28535 21.3141 6.70288C21.3141 6.12041 20.8419 5.6482 20.2594 5.6482H20.1844V5.57324C20.1844 4.99077 19.7122 4.51855 19.1297 4.51855C18.5472 4.51855 18.075 4.99077 18.075 5.57324V5.69277C16.7922 5.92459 15.8157 7.04875 15.8157 8.39748C15.8157 9.91342 17.0489 11.1467 18.5648 11.1467Z" fill={props.fill || '#5C6077'}/>
			 <path d="M25.5496 23.153L24.1263 24.5765C23.6126 23.4101 22.4456 22.5938 21.0914 22.5938H13.7083C10.8328 22.5938 9.64357 24.9348 9.38974 25.9829H7.75779V24.7781C7.75779 24.1957 7.28557 23.7235 6.7031 23.7235H1.05469C0.472219 23.7235 0 24.1957 0 24.7781V34.9453C0 35.5278 0.472219 36 1.05469 36H6.7031C7.28557 36 7.75779 35.5278 7.75779 34.9453V33.7407H22.3015C23.613 33.7407 24.8463 33.2298 25.7745 32.302L33.4317 24.6445C33.8436 24.2327 33.8432 23.5644 33.4317 23.1529C31.2586 20.9799 27.7228 20.9799 25.5496 23.153ZM5.64848 33.8906H2.10938V25.8328H5.64841V33.8906H5.64848ZM24.2831 30.8103C23.7535 31.3397 23.0497 31.6312 22.3015 31.6312H7.75786V28.0922H10.3193C10.9018 28.0922 11.374 27.62 11.374 27.0375C11.374 25.7474 12.4173 24.7031 13.7084 24.7031H21.0915C21.7557 24.7031 22.2962 25.2435 22.2962 25.9078C22.2962 26.8931 21.3872 27.1075 21.1124 27.1123C21.1118 27.1123 21.1112 27.1123 21.1109 27.1123C21.1044 27.1124 21.098 27.1124 21.0915 27.1124H17.2345C16.652 27.1124 16.1798 27.5847 16.1798 28.1671C16.1798 28.7496 16.652 29.2218 17.2345 29.2218H21.0919C21.9762 29.2218 22.808 28.8774 23.4347 28.2513L27.0413 24.6444C28.132 23.5538 29.775 23.3438 31.0784 24.0147L24.2831 30.8103Z" fill={props.fill || '#5C6077'}/>
			 <path d="M33.1573 6.51662L35.4168 5.38691C35.9378 5.12647 36.1489 4.49296 35.8884 3.97194C35.6279 3.45093 34.9945 3.23978 34.4734 3.50022L32.214 4.62993C31.693 4.89036 31.4819 5.52388 31.7424 6.04489C32.0039 6.56788 32.6384 6.77593 33.1573 6.51662Z" fill={props.fill || '#5C6077'} />
			 <path d="M35.4168 14.7973L33.1573 13.6676C32.6366 13.4074 32.0029 13.6183 31.7424 14.1393C31.4819 14.6603 31.693 15.2939 32.214 15.5543L34.4734 16.684C34.9938 16.944 35.6276 16.7339 35.8884 16.2123C36.1489 15.6913 35.9378 15.0577 35.4168 14.7973Z" fill={props.fill || '#5C6077'}/>
			 <path d="M2.84258 5.38691L5.10201 6.51662C5.62098 6.77601 6.25548 6.56795 6.51697 6.0449C6.77748 5.52388 6.56633 4.89036 6.04532 4.62993L3.7859 3.50022C3.26481 3.23978 2.63144 3.45093 2.37093 3.97194C2.11042 4.49296 2.32157 5.12647 2.84258 5.38691Z" fill={props.fill || '#5C6077'}/>
			 <path d="M3.7859 16.684L6.04532 15.5543C6.56633 15.2939 6.77748 14.6603 6.51697 14.1393C6.25647 13.6183 5.62274 13.4074 5.10201 13.6676L2.84258 14.7973C2.32157 15.0578 2.11042 15.6913 2.37093 16.2123C2.63179 16.734 3.26558 16.9441 3.7859 16.684Z" fill={props.fill || '#5C6077'}/>
		 </g>
		 <defs>
			 <clipPath id="clip0_866_1166">
				 <rect width="36" height="36" fill="white"/>
			 </clipPath>
		 </defs>
	 </svg>
  );
}

Money.propTypes = {
  'fill': PropTypes.string
};

export default Money;
