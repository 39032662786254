import React, { useRef, useEffect, useState } from 'react';
import './SlideUp.css'; // Import CSS file for styling

const SlideUp = ({ animationType = 'bottom', duration, delay, offset = 100, children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const slideUpRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !isVisible) {
                    setIsVisible(true);
                }
            });
        });

        observer.observe(slideUpRef.current);

        return () => observer.disconnect(); // Clean up the observer on unmount
    }, []);

    const getTransformValue = () => {
        switch (animationType) {
            case 'right':
                return `translateX(${isVisible ? 0 : offset}px)`;
            case 'left':
                return `translateX(${isVisible ? 0 : -offset}px)`;
            case 'top':
                return `translateY(${isVisible ? 0 : -offset}px)`;
            case 'bottom':
            default:
                return `translateY(${isVisible ? 0 : offset}px)`;
        }
    };

    const transitionStyle = {
        transition: `opacity ${duration}s ease-out ${delay}s, transform ${duration}s ease-out ${delay}s`,
        transform: getTransformValue(),
    };

    return (
        <div
            className={`slide-up-section ${isVisible ? 'is-visible' : ''} ${animationType}`}
            ref={slideUpRef}
            style={transitionStyle}
        >
            {/* Render children dynamically */}
            {children}
        </div>
    );
};

SlideUp.defaultProps = {
    duration: .4, // Default duration in seconds
    delay: .3, // Default delay in seconds
};

export default SlideUp;
