export default {
  ru: {
    headerTitle: 'Ритм Агент - решения для дистрибьюции',

    section1Title: 'Мотивируем сотрудников и поддерживаем дисциплину',
    //first
    firstProductText1: 'владеет информацией по своему маршруту и плану продаж на день, и по показателям АКБ и ассортименту.',
    firstProductSpan1: 'Торговый представитель',
    firstProductText2: 'заранее узнает какие товары нужно подготовить для доставщиков. В случае возврата товара, зав. склада узнает об этом в системе и начинает подготовку к приемке.',
    fisrtProductSpan2: 'Зав.склад',
    firstProductText3: 'получает информацию в виде задания куда и когда доставить необходимый товар.',
    fisrtProductSpan3: 'Доставщик',
    //second
    firstProductText4: 'о планируемых поступлениях, и при получении оплаты от курьера и инкасатора валидирует оплаты в системе',
    fisrtProductSpan4: 'Кассир уведомлен',
    firstProductText5: 'может мониторить процессы поставки и вести учет по партии товара, отгружен ли товар, поступил на зону таможенной очистки, прибыл на склад и т.д.',
    fisrtProductSpan5: 'Отдел снабжения',
    firstProductText6: 'настраивать индивидуальные задачи для каждого сотрудника и подробно настраивать процесс взаимодействия',
    firstProductSpan6: 'Система позволяет',

    secondProductMainTitle: 'Ритм - поможет увеличить продажи',
    //first
    secondProductTitle1: 'Маркетинговые акции',
    secondProductText1: 'Вы можете создавать скидочные, бонусные, балловые, кэшбэк акции, а также гибко настроить условия активации акций.',
    secondProductTitle2: 'Телеграм бот для клиентов',
    secondProductText2: 'Ваши покупатели могут размещать заказы, получать данные о конечном продукте, сверить баланс, наличие кэшбэка и баллов.\\n',
    secondProductTitle3: 'Рекомендованные заказы',
    secondProductText3: 'Беря в расчет остатки торговых точек, система дает рекомендации по количеству товара при оформлении заказа',
    //second
    secondProductTitle4: 'Рассылка клиентам',
    secondProductText4: 'Возможность отправки SMS или телеграмм сообщений контрагентам.\n',
    secondProductTitle5: 'Мотивация торговых агентов',
    secondProductText5: 'Каждый агент может увидеть свои рекорды и общий рейтинг подталкивает на новые свершения в продажах.',
    secondProductTitle6: 'Эффективность команды',
    secondProductText6: 'Система позволяет измерить показатели эффективности торговых агентов по распределению продаж по  ассортименту товаров, частоту возвратов товаров и АКБ.',

    phoneSectionMainTitle: 'Весь ваш бизнес как на одной ладони',
    phoneSectionTitle1: 'Аналитика',
    phoneSectionText1: 'Торговый представитель знает свой маршрут и план продаж, акб и по ассортименту за день',
    phoneSectionTitle2: 'Отчет о прибыли',
    phoneSectionText2: 'Курьер знает кому и сколько необходимо доставить заказы',
    phoneSectionTitle3: 'Отслеживание',
    phoneSectionText3: 'Отдел снабжения знает когда поставка выйдет с завода и прибудет в склад',
    phoneSectionTitle4: 'Анализ сотрудников',
    phoneSectionText4: 'Есть возможность ставить индивидуальные задачи каждому сотруднику',

    laptopMainTitle: 'Не теряйте время и деньги',
    laptopText1: 'Напомним запросить оплату у торговой точки в обещанный день платежа.',
    laptopText2: 'Удобная форма по средствам к получению в виде обратного отсчета.',
    laptopText3: 'Возможность провести сверку по балансу клиента не отходя от торговой точки.',
    laptopText4: 'Система сама составляет план сбора оплат, позволяя вам посмотреть сумму к получению через каждого инкассатора',
    laptopText5: 'Долги торговых точек сразу отображается на карте',
    laptopText6: 'Если у торговых точек есть долги, вы можете наложить ограничения на оформление новых продаж',

    solutionMainTitle: 'Готовые отчёты для анализа и решения проблем',
    solutionTitle1: 'Готовие отчеты',
    solutionText1: 'Большое количество сформированных отчетов по всем направлениям учета',
    solutionTitle2: 'Конструктор графиков',
    solutionText2: 'Обширная библиотека графиков, позволит выстроить удобный для каждого сотрудника дешборд с добавлением в виде виджетов.',
    solutionTitle3: 'Конструктор отчетов',
    solutionText3: 'Гибкий конструктор отчетов с возможностью сохранения сформированного отчета и быстрого доступа к нему\n'
  },

  uz: {
    headerTitle: 'Ritm agent - distributsiya uchun tayyor yechimlar',

    section1Title: "Hodimlarni rag'batlantiramiz va intizomni saqlashga qarashamiz",
    //first
    firstProductText1: 'O\'z yo\'nalishi va kunlik savdo rejasi, batareyaning ishlashi va assortimenti haqidagi ma\'lumotlarga ega.',
    firstProductSpan1: 'Savdo vakili',
    firstProductText2: 'yetkazib berish uchun qanday tovarlarni tayyorlash kerakligini oldindan biladi. Tovarlar qaytarilgan taqdirda, Ombor bu haqda tizimda bilib oladi va qabul qilishga tayyorlana boshlaydi.',
    fisrtProductSpan2: 'Omborxona boshlig\'i',
    firstProductText3: 'kerakli tovarni qayerda va qachon yetkazib berish kerakligi haqida topshiriq shaklida ma’lumot oladi.',
    fisrtProductSpan3: 'Yetkazib beruvchi',
    //second
    firstProductText4: 'rejalashtirilgan tushumlar bo\'yicha va kurer va kollektordan to\'lovni olgandan so\'ng, tizimda to\'lovlarni tasdiqlaydi',
    fisrtProductSpan4: 'Kassirga xabar berildi',
    firstProductText5: 'yetkazib berish jarayonlarini kuzatib borishi va tovarlar partiyasining hisobini yuritishi, tovarlar jo\'natilganligi, bojxona rasmiylashtiruvi zonasiga kirganligi, omborga kelganligi va hokazolarni yuritishi mumkin.',
    fisrtProductSpan5: 'Sotib olish bo\'limi',
    firstProductText6: 'har bir hodim uchun individual vazifalarni belgilash va o\'zaro ta\'sir jarayonini nozik sozlash',
    firstProductSpan6: 'Tizim ruxsat beradi',

    secondProductMainTitle: 'Ritm - savdoni oshirishga yordam beradi',
    //first
    secondProductTitle1: 'Marketing reklamalari',
    secondProductText1: 'Siz chegirmalar, bonuslar, ballar, naqd pullarni qaytarish aktsiyalarini yaratishingiz, shuningdek, aksiyalarni faollashtirish shartlarini moslashuvchan tarzda belgilashingiz mumkin.',
    secondProductTitle2: 'Telegram boti mijozlar uchun',
    secondProductText2: 'Mijozlaringiz buyurtma berishlari, yakuniy mahsulot haqida ma\'lumot olishlari, balans, naqd pul va ballarni tekshirishlari mumkin.\\n',
    secondProductTitle3: 'Tavsiya etilgan buyurtmalar',
    secondProductText3: 'Savdo nuqtalarining balansini hisobga olgan holda, tizim buyurtma berishda tovarlar miqdori bo\'yicha tavsiyalar beradi.',
    //second
    secondProductTitle4: 'Mijozlarga pochta jo\'natmalari',
    secondProductText4: 'Kontragentlarga SMS yoki telegramma xabarlarini yuborish imkoniyati.\n',
    secondProductTitle5: 'Savdo agentlarini rag\'batlantirish',
    secondProductText5: 'Har bir agent o\'z rekordlarini ko\'rishi mumkin va umumiy reyting ularni sotishda yangi yutuqlarga undaydi.',
    secondProductTitle6: 'Jamoa samaradorligi',
    secondProductText6: 'Tizim mahsulot assortimenti bo\'yicha sotishni taqsimlash, tovarlar va batareyalarni qaytarish chastotasi bo\'yicha savdo agentlarining ishlash ko\'rsatkichlarini o\'lchash imkonini beradi.',

    phoneSectionMainTitle: 'Bir qarashda butun biznesingiz',
    phoneSectionTitle1: 'Analitika',
    phoneSectionText1: 'Savdo vakili kuniga o\'zining marshruti va savdo rejasini, batareyani va assortimentini biladi',
    phoneSectionTitle2: 'Foyda hisoboti',
    phoneSectionText2: 'Kuryer kimga va qancha buyurtma berish kerakligini biladi',
    phoneSectionTitle3: 'Kuzatuv',
    phoneSectionText3: 'Ta\'minot bo\'limi yuk zavoddan qachon chiqib, omborga kelganini biladi',
    phoneSectionTitle4: 'Hodimlarni tahlil qilish',
    phoneSectionText4: 'Har bir hodim uchun individual vazifalarni belgilash mumkin',

    laptopMainTitle: 'Vaqt va pulni behuda sarflamang',
    laptopText1: 'Va\'da qilingan to\'lov kunida savdo nuqtasidan to\'lovni talab qilishni eslang.',
    laptopText2: 'Orqaga hisoblash ko\'rinishidagi debitorlik mablag\'larining qulay shakli.',
    laptopText3: 'Rozetkadan chiqmasdan mijozning balansini yarashtirish imkoniyati.',
    laptopText4: 'Tizimning o\'zi to\'lovlarni yig\'ish rejasini tuzadi, bu sizga har bir kollektor orqali olinadigan miqdorni ko\'rish imkonini beradi',
    laptopText5: 'Chakana savdo nuqtalarining qarzlari darhol xaritada ko\'rsatiladi',
    laptopText6: 'Agar savdo nuqtalarining qarzlari bo\'lsa, siz yangi savdolarni ro\'yxatdan o\'tkazishga cheklovlar qo\'yishingiz mumkin',

    solutionMainTitle: 'Moslashuvchan hisobot tizimi',
    solutionTitle1: 'Tayyor hisobotlar',
    solutionText1: "Barcha yo'nalishlar bo'yicha tayyor hisobotlardan foydalaning va o'z biznesingizni yanada rivojlantiring",
    solutionTitle2: 'Diagrammalar konstruktori',
    solutionText2: 'Diagrammalarning keng kutubxonasi sizga vidjetlar qo\'shilgan holda har bir hodim uchun qulay boshqaruv panelini yaratishga imkon beradi.',
    solutionTitle3: 'Hisobotlar konstruktori',
    solutionText3: 'Tizimda kerakli hisobot formatini sozlash va Excelga eksport qilish imkoniyati bilan birga barcha kerakli ma\'lumotlarni ko\'rsatish imkonini beruvchi moslashuvchan hisobot konstruktori mavjud.'
  },

  en: {
    headerTitle: 'Rhythm Agent - distribution solutions',

    section1Title: 'Each employee knows his plan and route in advance',
    //first
    firstProductText1: 'owns information on its route and sales plan for the day, and on battery performance and assortment.',
    firstProductSpan1: 'Sales Representative',
    firstProductText2: 'knows in advance what goods need to be prepared for deliveries. In the event of a return of goods, The warehouse learns about it in the system and starts preparing for acceptance.',
    fisrtProductSpan2: 'Warehouse manager',
    firstProductText3: 'receives information in the form of a task where and when to deliver the necessary goods.',
    fisrtProductSpan3: 'Deliveryman',
    //second
    firstProductText4: 'on planned receipts, and upon receipt of payment from the courier and collector, validates payments in the system',
    fisrtProductSpan4: 'Cashier Notified',
    firstProductText5: 'can monitor the delivery processes and keep records of the consignment of goods, whether the goods have been shipped, entered the customs clearance zone, arrived at the warehouse, etc.',
    fisrtProductSpan5: 'Purchase department',
    firstProductText6: 'set up individual tasks for each employee and fine-tune the interaction process',
    firstProductSpan6: 'The system allows',

    secondProductMainTitle: 'Rhythm - will help increase sales',
    //first
    secondProductTitle1: 'Marketing promotions',
    secondProductText1: 'You can create discount, bonus, points, cashback promotions, as well as flexibly set the conditions for activating promotions.',
    secondProductTitle2: 'Telegram bot for clients',
    secondProductText2: 'Your customers can place orders, get data about the final product, check the balance, cashback and points.\\n',
    secondProductTitle3: 'Recommended Orders',
    secondProductText3: 'Taking into account the balance of outlets, the system gives recommendations on the quantity of goods when placing an order',
    //second
    secondProductTitle4: 'Mailing to clients',
    secondProductText4: 'Ability to send SMS or telegram messages to counterparties.\n',
    secondProductTitle5: 'Motivation of sales agents',
    secondProductText5: 'Each agent can see their records and the overall rating pushes them to new achievements in sales.',
    secondProductTitle6: 'Team Efficiency',
    secondProductText6: 'The system allows you to measure the performance indicators of sales agents for the distribution of sales by product range, the frequency of returns of goods and batteries.',

    phoneSectionMainTitle: 'Your entire business at a glance',
    phoneSectionTitle1: 'Analytics',
    phoneSectionText1: 'The sales representative knows his route and sales plan, battery and assortment per day',
    phoneSectionTitle2: 'Profit Statement',
    phoneSectionText2: 'The courier knows to whom and how much orders need to be delivered',
    phoneSectionTitle3: 'Tracking',
    phoneSectionText3: 'The supply department knows when the shipment leaves the factory and arrives at the warehouse',
    phoneSectionTitle4: 'Employee analysis',
    phoneSectionText4: 'It is possible to set individual tasks for each employee',

    laptopMainTitle: 'Don\'t waste time and money',
    laptopText1: 'Recall to request payment from the outlet on the promised payment date.',
    laptopText2: 'A convenient form of funds receivable in the form of a countdown.',
    laptopText3: 'The ability to reconcile the client\'s balance without leaving the outlet.',
    laptopText4: 'The system itself draws up a payment collection plan, allowing you to see the amount to be received through each collector',
    laptopText5: 'Debts of retail outlets are immediately displayed on the map',
    laptopText6: 'If outlets have debts, you can impose restrictions on registration of new sales',

    solutionMainTitle: 'Flexible reporting system',
    solutionTitle1: 'Report Designer',
    solutionText1: 'The system has a flexible reporting constructor that allows you to customize the desired report format and display all the necessary data, coupled with the ability to export to Excel.',
    solutionTitle2: 'Chart Builder',
    solutionText2: 'An extensive library of charts will allow you to build a dashboard convenient for each employee with the addition of widgets.',
    solutionTitle3: 'Document constructor',
    solutionText3: 'This function makes it possible to create new document templates on your own for their subsequent generation in the system.\n'
  },
}
