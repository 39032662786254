import React from 'react';

export default function Telegram (props) {
  return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1143_3996)">
                <path d="M0.870922 10.625L5.70974 12.431L7.58266 18.4543C7.7025 18.8401 8.17423 18.9827 8.48734 18.7267L11.1846 16.5278C11.4673 16.2975 11.87 16.286 12.1655 16.5005L17.0304 20.0325C17.3653 20.2759 17.8398 20.0924 17.9238 19.6876L21.4876 2.54527C21.5793 2.10315 21.1449 1.73432 20.7239 1.89714L0.865252 9.55801C0.375182 9.74701 0.379452 10.4409 0.870922 10.625ZM7.28082 11.4696L16.7377 5.64515C16.9076 5.54078 17.0825 5.77059 16.9366 5.90597L9.1319 13.1608C8.85757 13.4161 8.68061 13.7579 8.63049 14.1288L8.36463 16.099C8.32942 16.3621 7.95989 16.3882 7.8873 16.1336L6.86481 12.5409C6.7477 12.1311 6.91836 11.6934 7.28082 11.4696Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_1143_3996">
                    <rect width="21" height="21" fill="white" transform="translate(0.5 0.5)"/>
                </clipPath>
            </defs>
        </svg>

  );
}
