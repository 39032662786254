import React from 'react';
import { Button as MUIButton } from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CustomButton = styled(MUIButton)`
  background-color: ${({ background }) => background || '#18214D'} !important;
  border-radius: ${({ radius }) => radius || '11px'} !important;
  color: ${({ color }) => color || '#fff'} !important;
  text-transform: ${({ transform }) => transform || 'inherit'} !important;
  font-family: ${({ fontFamily }) => fontFamily || 'SemiBold'} !important;
  font-size: ${({ size }) => size || '16px'} !important;
  width: ${({ width }) => width || '188px'} !important;
  min-width: ${({ width }) => width || '188px'} !important;
  height: ${({ height }) => height || '49px'} !important;
  margin: ${({ margin }) => margin} !important;
	@media only screen and  (max-width: 992px) {
		& {
      width: 100% !important;
		}
	}
`;

const Button = (props) => {
  const { children } = props;
  return (
	 <CustomButton {...props}>
		 {children}
	 </CustomButton>
  );
};

Button.propTypes = {
  'children': PropTypes.any
};

export default Button;
