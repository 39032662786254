// Possibilities
// Solutions
// Prices
// Education
// About us

export const menuItems = [
  {
    'label': 'possibilities',
    'href': '/#possibilities'
  },
  {
    'label': 'solutions',
    'href': '/#solutions'
  },
  {
    'label': 'prices',
    'href': '/#pricing'
  },
  {
    'label': 'column3Title3',
    'href': '/#reviews'
  },
  {
    'label': 'education',
    'href': '/#about-us'
  },
  // {
  //   'label': 'TurnkeySolutions',
  //   'href': '/rhythm-agent'
  // },
  // {
  //   'label': 'whyRitm',
  //   'href': '/#shops'
  // },
  // {
  //   'label': 'prices',
  //   'href': '/prices'
  // },
  // {
  //   'label': 'company',
  //   'href': '/company'
  // },
  // {
  //   'label': 'CarWeights',
  //   'href': '/#weight'
  // },
  // {
  //   'label': 'InternetShop',
  //   'href': '/#e-commere'
  // },
  // {
  //   'label': 'FAQ',
  //   'href': '/faq'
  // }
];

export const columnFirst = [
  {
    'label': 'column1Title1',
    'href': '/rhythm-agent'
  },
  {
    'label': 'column1Title2',
    'href': '#shops'
  },
  {
    'label': 'column1Title3',
    'href': '#telgram-bot'
  },
  {
    'label': 'column1Title4',
    'href': '#weight'
  },
  {
    'label': 'column1Title5',
    'href': '#e-commere'
  }
];

export const columnSecond = [
  {
    'label': 'column2Title1',
    'href': '/company'
  },
  {
    'label': 'column2Title2',
    'href': '#contact'
  },
  {
    'label': 'column2Title3',
    'href': '#contact'
  },
  {
    'label': 'column2Title4',
    'href': '#contact'
  }
];

export const columnThird = [
  {
    'label': 'column3Title1',
    'href': '#contact'
  },
  {
    'label': 'column3Title2',
    'href': '#contact'
  },
  {
    'label': 'column3Title3',
    'href': '#contact'
  }
];
