import noImage from 'media/Image/gatoviy_otchet.jpeg';
import img2 from 'media/Image/konstruktor_grafik.jpg';
import img3 from 'media/Image/new/constructor.png';
import Trolley from 'media/Icon/Trolley';
import Headset from 'media/Icon/Headset';
import Shape from 'media/Icon/Shape';
import React from 'react';

export const report = [
  {
    'title': 'solutionTitle1',
    'img': noImage,
    'text': 'solutionText1'
  },
  {
    'title': 'solutionTitle2',
    'img': img2,
    'text': 'solutionText2'
  },
  {
    'title': 'solutionTitle3',
    'img': img3,
    'text': 'solutionText3'
  }
];

export const solutions = [
  {
    'icon': <Trolley />,
    'title': 'migration_to_your_server',
    'text': 'migration_to_your_server_text'
  },
  {
    'icon': <Headset />,
    'title': 'professional_technical_support',
    'text': 'professional_technical_support_text'
  },
  {
    'icon': <Shape />,
    'title': 'integration_with',
    'text': 'integration_with_text'
  }
];
