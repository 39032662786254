import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import bg from 'media/Image/bg_collations.png'
import example from './eample.jpg'
import MainContainer from "../MainContainer";
import {Col, Row} from "react-bootstrap";
import SlideUp from "../SlideUp/SlideUp";
import {useTranslation} from "react-i18next";


const ImageBackground = styled('div')`
  width: 100%;
  @media only screen and (max-width: 992px) {
    & .row > :first-child {
      padding: 40px !important;

      & > :last-child {
        margin-left: 0 !important;
      }
    }
  }
`

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  background: url(${bg});
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
`

const Block = styled(Col)`
  min-height: 400px;
  background-repeat: no-repeat;
  @media (max-width: 992px) {
    min-height: auto !important;
    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

`

const ImageBlock = styled(Col)`
  padding-left: 20px !important;
  margin: auto !important;
  height: fit-content;
  padding-right: 135px !important;
`

const Title = styled('p')`
  font-family: var(--bold);
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 150%;
  color: #18214D;
  margin-bottom: 20px;
`;

const SubTitle = styled('h4')`
  color: #151515;
  font-size: 24px;
  line-height: 1.25;
`

const Text = styled('p')`
  width: 100%;
  margin-top: 12px;
  min-height: 215px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #363940;
`

const Timeline = styled('ul')`
  list-style: none;
  display: flex;
  margin-left: -30px;
  align-items: center;
  position: relative;
  justify-content: space-between;



  &:before {
    position: absolute;
    content: '';
    top: 17px;
    right: 20px;
    height: 6px;
    width: 1000%;
    background: #2684FF;
  }

  & li {
    cursor: pointer;
    margin-top: 40px;
    font-weight: 600;
    font-family: var(--semi-bold);
    font-size: 18px;
    user-select: none;

    position: relative;

    &.active > span {
      position: absolute;
      content: '';
      top: -30px;
      right: 10px;
      height: 17px;
      width: 17px;
      border-radius: 50%;
      background: #FFF;
      
    }
    &:before {
      position: absolute;
      content: '';
      top: -34px;
      right: 6px;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      background: #2684FF;
    }

  }
`

const Collaction = () => {
    const [year, setYear] = useState(2024)

    const {t} = useTranslation()

    const years = {
        2020: t('2020_text'),
        2021: t('2021_text'),
        2022: t('2022_text'),
        2023: t('2023_text'),
        2024: t('2024_text'),
    }
    // useEffect(() => {
    //     setTimeout(() => {
    //         if (year === 2024) {
    //             setYear(2020)
    //         } else {
    //             setYear(year + 1)
    //         }
    //     }, 5000)
    // }, [year]);

    return (
        <ImageBackground id={'about-us'}>
            <Wrapper>
                <MainContainer withouMedia>
                    <Row>
                        <ImageBlock xs={12} lg={6}>
                            <SlideUp animationType={'top'}>
                                <Title>
                                    {t('about_us')}
                                </Title>
                            </SlideUp>
                            <Text>
                                <SlideUp animationType={'bottom'}>
                                    {years[year]}
                                </SlideUp>
                            </Text>
                            <Timeline>
                                <li onClick={() => setYear(2020)} className={year === 2020 ? 'active' : ''}>2020 <span/>
                                </li>
                                <li onClick={() => setYear(2021)} className={year === 2021 ? 'active' : ''}>2021 <span/>
                                </li>
                                <li onClick={() => setYear(2022)} className={year === 2022 ? 'active' : ''}>2022 <span/>
                                </li>
                                <li onClick={() => setYear(2023)} className={year === 2023 ? 'active' : ''}>2023 <span/>
                                </li>
                                <li onClick={() => setYear(2024)}
                                    className={year === 2024 ? 'active' : ''}> 2024 <span/></li>
                            </Timeline>
                        </ImageBlock>
                        <Block xs={12} lg={6}>
                            <img alt={''} src={example}/>
                        </Block>
                    </Row>
                </MainContainer>
            </Wrapper>
        </ImageBackground>
    )
}

export default Collaction
