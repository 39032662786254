import React from "react";
import styled from "styled-components";
import MainContainer from "../../MainContainer";
import bg from "../../../media/Image/bg.svg";
import Navbar from "../../Navbar";
import Footer from "../../Footer/Footer";
import {useTranslation} from "react-i18next";
import {Rules} from "../../Rules/Rules";
import _ from 'lodash';

const Wrapper = styled('div')`
    height: 100%;
`

const Header = styled('div')`
    margin-top: 100px;

    @media only screen and (max-width: 1170px) {
        padding: 20px;
    }
`

const Title = styled('p')`
  font-family: var(--bold);
  font-style: normal;
  font-weight: bold;
  font-size: 41px;
  line-height: 150%;
  text-align: center;
  color: #2684FF;
  @media only screen and (max-width: 580px) {
    font-size: 34px;
    text-align: left;
  }
  @media only screen and (max-width: 576px) {
    width: 100%;
    margin: auto;
  }
  @media only screen and (max-width: 500px) {
    font-size: 24px;
    width: 100%;
    margin-bottom: 8px !important;
  }
  @media only screen and (max-width: 350px) {
    font-size: 24px;
    width: 100%;
  }
`;

const ContentBox = styled('div')`
    margin-bottom: 50px;
`

const Content = styled('div')`
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 25px;

    @media only screen and (max-width: 1170px) {
        padding: 0 20px;
    }
    
    .title {
        font-style: normal;
        font-family: var(--bold);
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #18214D;
        margin-top: 25px;
    }
    
    .text {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 180%;
        color: #5C6077;
    }
`

const PrivacyPolicy = () => {
    const {t} = useTranslation()

    return (
        <Wrapper>
            <MainContainer style={{margin: 'auto'}} mP={'opx'}>
                <Navbar bg={bg}/>
                <Header>
                    <Title>
                        Политика конфиденциальности
                    </Title>
                </Header>
                <ContentBox>
                    {_.map(Rules, (item) => {
                        const title = _.get(item, 'title')
                        const texts = _.get(item, 'texts')
                        return (
                            <Content>
                                <div className={'title'}>
                                    {title}
                                </div>
                                {_.map(texts, (text) => {
                                    return (
                                        <div className={'text'}>
                                            {text}
                                        </div>
                                    )
                                })}
                            </Content>
                        )
                    })}
                </ContentBox>
            </MainContainer>
            <Footer t={t} />
        </Wrapper>
    )
}

export default PrivacyPolicy
